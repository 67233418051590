import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContractFilter, ContractService } from '@core/services/contract/contract.service';
import { Subject } from 'rxjs';
import { ContractSeller, ContractSellerItem } from '@core/models/contract-seller.model';

@Component({
  selector: 'app-contract-seller-filters',
  templateUrl: './contract-seller-filters.component.html',
  styleUrls: ['./contract-seller-filters.component.scss']
})
export class ContractSellerFiltersComponent implements OnInit {

  @Input() sellerList: ContractSeller[];
  @Input() sellerToTransferList!: ContractSeller[]
  @Input() transferContracts!: ContractSellerItem[];

  @Output() sellerChangeEmitter= new EventEmitter<
    {
      seller: ContractSeller;
      year: number;
    }>();
  @Output() transferContractsEmitter= new EventEmitter<
    {
      contracts?: ContractSellerItem[];
      year?: number;
      seller?: ContractSeller;
      sellerToTransfer: ContractSeller;
    }>();
  @ViewChild('sellerToTransferSelector') sellerToTransferSelector;

  seller: ContractSeller = null;
  sellerToTransfer: ContractSeller = null;
  transferSellerActionDisable = true;
  filter: ContractFilter = {
    year: this.getCurrentYear(),
  };

  componentDestroyed$: Subject<void> = new Subject();
  yearSelected: number = null;
  isLoadingItems = false;

  constructor(
    private contractService: ContractService
  ) { }


  ngOnInit(): void {
    this.sellerToTransferList = [];
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  handleYearFilterValueChange($event) {
    this.yearSelected = $event.toString()
    this.getSellerByYear($event.toString());
    this.sellerChangeEmitter.emit(null);
  }

  handleSellerValueChange(seller: ContractSeller) {
    this.seller = seller;

    this.sellerChangeEmitter.emit({
      seller: seller,
      year: this.yearSelected
    });
    this.transferSellerActionDisable = true;
    this.transferContracts = [];
    this.sellerToTransfer = null;
    this.sellerToTransferSelector ? this.sellerToTransferSelector.init(): null ;
  }

  handleContractTransferValueChange(seller: ContractSeller) {
    this.sellerToTransfer = seller;
    this.transferSellerActionDisable = (seller === null);
  }

  getCurrentYear() {
    return new Date().getFullYear().toString();
  }

  getSellerByYear(year) {
    this.isLoadingItems = true;
    this.contractService
      .getSellerByYear(year)
      .subscribe(
        sellers => {
          this.sellerList = sellers;
          this.isLoadingItems = false;
        },
        error => {
          console.error('Error while getting seller list: ', error);
          this.isLoadingItems = false;
        }
      );
  }

  transferContractsToSeller() {
    this.transferContractsEmitter.emit({
        sellerToTransfer: this.sellerToTransfer
      });
  }
}
