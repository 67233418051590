import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContractItem } from '@core/models/contract-item.model';
import { ContractState } from '@core/models/contract-state.model';
import { Contract } from '@core/models/contract.model';
import { BaseService } from '@core/services/base/base.service';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractSeller, ContractSellerItem, JsonContractSellerItem } from '@core/models/contract-seller.model';

export interface ContractFilter {
  year: string;
  codUt?: string;
  nomUt?: string;
  searchElementById?: string;
  searchElementByName?: string;
  itemsPerPage?: number;
  page?: number;
}

export interface ContractStateFilter {
  year: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContractService extends BaseService {
  constructor(private httpService: HttpClient, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  public getContractList(filter): Observable<Contract[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des contrats. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/contracts?' + this.getParamsFromFilter(filter);

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            let contracts: Contract[] = [];
            if (response && Array.isArray(response)) {
              contracts = response.map(contract => new Contract(contract));
            }
            return contracts;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  // public getContract(id): Observable<Contract> {
  //   const httpErrors: HttpError[] = [
  //     {
  //       statusCode: 404,
  //       message: 'Impossible de récuperer le contrat. Veuillez essayer plus tard.',
  //     },
  //     {
  //       statusCode: 500,
  //       message: 'Une erreur est survenue. Veuillez essayer plus tard.',
  //     },
  //   ];
  //
  //   let url = environment.contract_microservice_api_base_url + '/contract/' + id;
  //
  //   return new Observable<Contract>(observer => {
  //     this.httpService
  //       .get(url)
  //       .pipe(
  //         map(response => {
  //           if (response && response['statusCode'] === 200) {
  //             return new Contract(response['data'] as Contract);
  //           } else {
  //             throw new Error('Erreur lors de la récupération du contrat');
  //           }
  //         })
  //       )
  //       .subscribe(
  //         (response: any) => observer.next(response),
  //         error => {
  //           this.errorHandlerService.showErrorMessage(httpErrors, error.status);
  //           observer.error(error);
  //         }
  //       );
  //   });
  // }

  public getContractStateList(filter): Observable<ContractState[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des états du contrat. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/contract_states?' + this.getParamsFromFilter(filter);

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            let contractStates: ContractState[] = [];
            if (response && Array.isArray(response)) {
              contractStates = response.map(contract => new ContractState(contract));
            }
            return contractStates;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  public getContractItem(filter): Observable<ContractItem> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: "Impossible de récuperer l'item contrat. Veuillez essayer plus tard.",
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/contract_items/' + filter.year + '/' + filter.searchElementById;

    return new Observable<ContractItem>(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            if (response && response['statusCode'] === 200) {
              return new ContractItem(response['data'] as ContractItem);
            }

            return null;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  public getContractSellerToTransfer(year: string, ut: string): Observable<ContractSeller[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des contrats. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/contract/seller/transfer/'
      + 'year/' + year + '/ut/' +ut;

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            let contractSellers: ContractSeller[] = [];
            if (response && Array.isArray(response['data'] ?? null)) {
              contractSellers = response['data'].map(contractSeller => new ContractSeller(contractSeller));
            }
            return contractSellers;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  public getContractSellerListByYear(year: string, ut: string ): Observable<ContractSellerItem[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des contrats. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/contract/seller/' + 'year/' + year + '/ut/' +ut;

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            let ContractSellerItems: JsonContractSellerItem[] = [];
            if (response && Array.isArray(response['data'] ?? null)) {
              ContractSellerItems = response['data'].map(contractSellerItem =>
                new ContractSellerItem(contractSellerItem));
            }
            return ContractSellerItems;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  public getSellerByYear(year: string): Observable<ContractSeller[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des contrats. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/contract/seller/year/' + year;

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            let contractSellers: ContractSeller[] = [];
            if (response && Array.isArray(response['data'] ?? null)) {
              contractSellers = response['data'].map(contractSeller => new ContractSeller(contractSeller));
            }
            return contractSellers;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  public transferContractsToSeller(
    year: string,
    ut: string,
    sellerToTransfer: string,
    contracts: ContractSellerItem[]
  ): Observable<ContractSeller[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des contrats. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/contract/seller/transfer/'
      + 'year/' + year + '/ut/' + ut + '/to/' + sellerToTransfer;

    const transferContracts =
      contracts.map(contract => contract.toTransferJson());

    return new Observable(observer => {
      this.httpService
        .post(url, transferContracts)
        .subscribe(
          (response: any) => {
            return observer.next(response)
          },
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }
}
