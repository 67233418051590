import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TvConfirmationData } from '@core/models/tv-confirmation-data.model';
import { AuthService } from '@core/services/auth.service';
import { ScreenService } from '@core/services/screen/screen.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TvConfirmPopupComponent } from '../tv-confirm-popup/tv-confirm-popup.component';

@Component({
  selector: 'app-screen-dialog',
  templateUrl: './screen-dialog.component.html',
  styleUrls: ['./screen-dialog.component.scss'],
})
export class ScreenDialogComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<void> = new Subject();
  screenForm: FormGroup;
  loading = false;
  statusIsSame = false;
  screenInClosureDate = false;
  screenDurCalMax = '';
  selectedView;
  userCode = '';
  screenSelectedType = '';
  screenToUpdatePrice = {};
  screensSelectedCopy = [];
  screensToUpdate = [];
  screenStatus: {}[] = [];
  screenStatusToUse = [];
  screenPriceLib: [];
  screenTypes: [];
  selectedType = [];
  individualTypes = [];
  screenTypeToDelete = [];
  screenTypeToInsert = [];
  spotsList = [];
  screenHistory = [];

  screenKeyToCompare = [
    'screenStatus',
    'screenDate',
    'screenHour',
    'screenHourDiff',
    'screenDurMax',
    'screenDurOuv',
    'screenForcable',
    'screenComment',
  ];

  readonly displayViews: { display: string; id: number }[] = [
    { display: 'Information', id: 0 },
    { display: 'Historique', id: 1 },
    { display: 'Spots', id: 2 },
  ];

  constructor(
    public screenService: ScreenService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<ScreenDialogComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.getScreenStatus();
    this.getScreenSpots();
    this.getScreenHistory();
    this.getScreenTypes();
    this.getUserCode();
    this.getScreenDurCalMax();
    this.initForm();
    this.initValue();
    this.valueChanges();

    if (!this.data.isCreating) {
      this.screensSelectedCopy = JSON.parse(JSON.stringify(this.data.screenSelected));
      this.compareAndSetScreenPriceValue();
      this.compareAndSetScreenInfoValue();
      this.pricesValueChange();
      this.checkTemConduct();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  initForm(): void {
    let screenDate = '';
    if (this.data?.screenDate?.startDate && this.data?.screenDate?.endDate) {
      screenDate = `${moment(this.data.screenDate.startDate, 'DD/MM/YYYY').format('DD/MM/YYYY')} - ${moment(
        this.data.screenDate.endDate,
        'DD/MM/YYYY'
      ).format('DD/MM/YYYY')}`;
    }

    if (this.data.isCreating || (!this.data.isCreating && this.data.screenSelected.length >= 2)) {
      this.screenForm = this.fb.group({
        screenHour: [null, Validators.required],
        screenStatus: [null, Validators.required],
        screenDate: [this.data.isCreating ? screenDate : null, Validators.required],
        screenHourDiff: [null, Validators.required],
        screenDurMax: [null, [Validators.required, Validators.maxLength(5)]],
        screenDurOuv: [null, [Validators.required, Validators.maxLength(5)]],
        screenForcable: [null, [Validators.required]],
        screenComment: [this.data.isCreating ? 'Nouvel écran' : '', [Validators.maxLength(30)]],
        screenPrices: this.fb.group({
          secteur_1: [null],
          secteur_2: [null],
          secteur_3: [null],
          tarif_20h15: [null],
          tarif_unique: [null],
          achat_unitaire: [null],
          captif: [null],
          international: [null],
        }),
      });
    } else {
      let screenStatusSelected = {
        libtype: this.data.screenSelected[0].screenStatus,
        codtype: this.data.screenSelected[0].screenStatusId,
      };

      this.screenForm = this.fb.group({
        screenHour: [this.data.screenSelected[0].screenHour, Validators.required],
        screenStatus: [screenStatusSelected, Validators.required],
        screenDate: [moment(this.data.screenSelected[0].screenDate).format('DD/MM/YYYY'), Validators.required],
        screenHourDiff: [this.data.screenSelected[0].screenHourDiff.slice(0, 5), Validators.required],
        screenDurMax: [this.data.screenSelected[0].screenDurMax, [Validators.required, Validators.maxLength(5)]],
        screenDurOuv: [this.data.screenSelected[0].screenDurOuv, [Validators.required, Validators.maxLength(5)]],
        screenForcable: [this.data.screenSelected[0].screenForcable, [Validators.required]],
        screenComment: [this.data.screenSelected[0].screenComment, [Validators.maxLength(30)]],
        screenPrices: this.fb.group({
          secteur_1: [this.data.screenSelected[0].secteur_1],
          secteur_2: [this.data.screenSelected[0].secteur_2],
          secteur_3: [this.data.screenSelected[0].secteur_3],
          tarif_20h15: [this.data.screenSelected[0].tarif_20h15],
          tarif_unique: [this.data.screenSelected[0].tarif_unique],
          achat_unitaire: [this.data.screenSelected[0].achat_unitaire],
          captif: [this.data.screenSelected[0].captif],
          international: [this.data.screenSelected[0].international],
        }),
      });
    }
  }

  /**
   * Compare all screenPrice of screens selected and set default value if price are differents
   */
  compareAndSetScreenPriceValue() {
    this.data.screenPriceLib.forEach(priceLib => {
      if (!this.areValuesSameForKey(this.data.screenSelected, priceLib)) {
        this.screenForm.get('screenPrices').get(priceLib).setValue('Mixte', { emitEvent: false });
      } else {
        this.screenForm.get('screenPrices').get(priceLib).setValue(this.data.screenSelected[0][priceLib], { emitEvent: false });
      }
    });
  }

  /**
   * Compare all data of screens selected and set default value if data are differents
   */
  compareAndSetScreenInfoValue() {
    this.screenKeyToCompare.forEach(key => {
      if (!this.areValuesSameForKey(this.data.screenSelected, key)) {
        this.screenForm.get(key).setValue('Mixte', { emitEvent: false });
      } else {
        if (key === 'screenHourDiff') {
          this.screenForm.get('screenHourDiff').setValue(this.data.screenSelected[0]['screenHourDiff'].slice(0, 5), { emitEvent: false });
        } else if (key === 'screenDate') {
          this.screenForm
            .get('screenDate')
            .setValue(moment(this.data.screenSelected[0].screenDate).format('DD/MM/YYYY'), { emitEvent: false });
        } else if (key === 'screenStatus') {
          this.statusIsSame = true;
          this.screenForm
            .get('screenStatus')
            .setValue(
              { libtype: this.data.screenSelected[0].screenStatus, codtype: this.data.screenSelected[0].screenStatusId },
              { emitEvent: false }
            );
        } else {
          this.screenForm.get(key).setValue(this.data.screenSelected[0][key], { emitEvent: false });
        }
      }
    });
  }

  /**
   * Check tem conduct and disable input if Y
   */
  checkTemConduct() {
    for (let obj of this.data.screenSelected) {
      if (obj.screenTemconduct === 'Y') {
        this.screenForm.get('screenStatus').disable();
        this.screenForm.get('screenDurMax').disable();
        this.screenForm.get('screenDurOuv').disable();

        this.screenInClosureDate = true;
        break;
      }
    }
  }

  /**
   * Check if value of the key is the same as the reference value of first object
   * @param arr
   * @param key
   * @returns
   */
  areValuesSameForKey(arr, key) {
    const referenceValue = arr[0][key];
    for (let i = 1; i < arr.length; i++) {
      if (arr[i][key] !== referenceValue) {
        return false;
      }
    }
    return true;
  }

  valueChanges(): void {
    this.screenForm
      .get('screenForcable')
      .valueChanges.pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (!this.data.isCreating) {
            this.screensSelectedCopy.forEach(screen => {
              screen['screenForcable'] = data;
            });
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );

    this.screenForm
      .get('screenHourDiff')
      .valueChanges.pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (!this.data.isCreating) {
            this.screensSelectedCopy.forEach(screen => {
              screen['screenHourDiff'] = data;
            });
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );

    this.screenForm
      .get('screenStatus')
      .valueChanges.pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (!this.data.isCreating) {
            this.screensSelectedCopy.forEach(screen => {
              screen['screenStatus'] = data.libtype;
              screen['screenStatusId'] = data.codtype;
            });
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );

    this.screenForm
      .get('screenDurMax')
      .valueChanges.pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (this.screenDurCalMax && this.screenForm.get('screenDurMax').value !== 'Mixte') {
            if (this.screenForm.get('screenDurMax').value <= this.screenDurCalMax) {
              this.screenForm.get('screenDurOuv').setValue(this.screenForm.get('screenDurMax').value);

              if (!this.data.isCreating) {
                this.screensSelectedCopy.forEach(screen => {
                  screen['screenDurMax'] = data;
                });
              }
            } else {
              this.snackBar.open('Calibre écran > calibre max!', null, {
                duration: 2000,
                verticalPosition: 'top',
                panelClass: ['chip-error'],
              });
            }
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );

    this.screenForm
      .get('screenDurOuv')
      .valueChanges.pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (this.screenForm.get('screenDurOuv').value > this.screenForm.get('screenDurMax').value) {
            this.snackBar.open('La durée ouverte ne peut pas être supérieure à la durée max!', null, {
              duration: 2000,
              verticalPosition: 'top',
              panelClass: ['chip-error'],
            });
          }
          if (!this.data.isCreating) {
            this.screensSelectedCopy.forEach(screen => {
              screen['screenDurOuv'] = data;
            });
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );
  }

  pricesValueChange() {
    this.data.screenPriceLib.forEach(priceLib => {
      this.screenForm
        .get('screenPrices')
        .get(priceLib)
        .valueChanges.pipe(debounceTime(1000), takeUntil(this.componentDestroyed$))
        .subscribe(
          data => {
            if (!this.data.isCreating) {
              this.screensSelectedCopy.forEach(screen => {
                screen[priceLib] = data;
              });
            }
          },
          (error: Error) => {
            console.error(error);
          }
        );
    });
  }

  getUserCode(): void {
    this.userCode = this.authService.currentUserData ? this.authService.currentUserData.sub : '';
  }

  getScreenStatus(): void {
    this.screenService
      .getScreenStatus()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data: []) => {
          if (data) {
            data.forEach(element => {
              if (element['codtype'] === '2' || element['codtype'] === '3') {
                this.screenStatusToUse.push(element);
              }
            });
            this.screenStatus = data;
            if (!this.data.isCreating && this.screensSelectedCopy) {
              this.populateScreenSelectedStatus();
            }
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );
  }

  getScreenSpots() {
    if (this.data?.screenSelected?.length === 1) {
      let screenData = {
        screenChannelCode: this.data?.screenChannel?.channelCode,
        screenChannelRegionCode: this.data?.screenChannel?.channelRegionCode,
        screenStartDate: this.data?.screenSelected[0]?.screenDate,
        screenHour: this.data?.screenSelected[0]?.screenHour,
        screenStatus: this.data?.screenSelected[0]?.screenStatusId,
      };

      this.screenService
        .getSpotsByScreen(screenData)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          result => {
            this.spotsList = result;
            if (this.spotsList.length > 0) {
              this.displayViews[2].display = `Spots (${this.spotsList.length})`;
            }
            this.loading = false;
          },
          (error: Error) => {
            this.loading = false;
            console.error(error);
            this.dialogRef.close();
          }
        );
    }
  }

  getScreenHistory() {
    if (this.data?.screenSelected?.length === 1) {
      let screenData = {
        screenChannelCode: this.data?.screenChannel?.channelCode,
        screenChannelRegionCode: this.data?.screenChannel?.channelRegionCode,
        screenChannelRegionTem: this.data?.screenChannel?.channelIsRegion,
        screenStartDate: this.data?.screenSelected[0]?.screenDate,
        screenHour: this.data?.screenSelected[0]?.screenHour,
      };

      this.screenService
        .getScreenHistory(screenData)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          result => {
            this.screenHistory = result;
            this.loading = false;
          },
          (error: Error) => {
            this.loading = false;
            console.error(error);
            this.dialogRef.close();
          }
        );
    }
  }

  getScreenDurCalMax(): void {
    let screenInfo = {};
    if (this.data.isCreating && this.data && this.data.screenChannel && this.data.screenDate) {
      screenInfo = {
        channel_code: this.data.screenChannel.channelCode,
        screen_year: moment(this.data.screenDate.startDate, 'DD/MM/YYYY').format('YYYY'),
      };
    } else {
      screenInfo = {
        channel_code: this.data.screenSelected[0].screenChannel,
        screen_year: moment(this.data.screenSelected[0].screenDate, 'DD/MM/YYYY').format('YYYY'),
      };
    }
    this.screenService
      .getScreenDurCalMax(screenInfo)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data: string) => {
          this.screenDurCalMax = data;
        },
        (error: Error) => {
          console.error(error);
        }
      );
  }

  initValue(): void {
    this.selectedView = this.displayViews[0].id;
    this.screenPriceLib = this.data.screenPriceLib;
  }

  selectedDisplayView(view): void {
    this.selectedView = view.id;
  }

  onCancelButtonClick(): void {
    this.dialogRef.close();
  }

  submitForm(): void {
    const confirmationData: TvConfirmationData = {
      title: '',
      content: `${
        this.data.isCreating
          ? 'Êtes-vous sûr de vouloir créer cet/ces écran(s)?'
          : 'Êtes-vous sûr de vouloir enregistrer vos modification(s)?'
      }`,
      okButtonTitle: 'Oui',
      cancelButtonTitle: 'Annuler',
    };

    const dialogRef = this.dialog.open(TvConfirmPopupComponent, {
      data: confirmationData,
      panelClass: 'tv-ns-dialog-container',
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        if (this.data.isCreating) {
          if (this.screenForm.valid) {
            this.loading = true;
            this.screenService
              .postScreen(this.buildScreenData())
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                result => {
                  let resultData = {
                    data: result,
                    screenForm: this.screenForm.value,
                  };
                  this.dialogRef.close(resultData);
                  this.loading = false;
                },
                (error: Error) => {
                  this.loading = false;
                  console.error(error);
                  this.dialogRef.close();
                }
              );
          }
        } else {
          this.loading = true;
          this.screenService
            .updateScreen(this.buildScreenData())
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
              result => {
                this.dialogRef.close(result);
                this.loading = false;
              },
              (error: Error) => {
                this.loading = false;
                console.error(error);
                this.dialogRef.close();
              }
            );
        }
      }
    });
  }

  /**
   * Get screen type (sport, so garanty, ...)
   */
  getScreenTypes(): void {
    this.screenService
      .getScreenTypes()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: []) => {
        this.screenTypes = data;
        if (!this.data.isCreating) {
          this.populateScreenSelectedType();
        }
      });
  }

  /**
   * Return codevt list of parents and children of types selected
   * @param types
   * @returns
   */
  getCodevtList(types: any, insert: boolean): any[] {
    let codevtList = [];
    types.forEach(element => {
      if (!codevtList.includes(element.codevt)) {
        codevtList.push(element.codevt);
      }
      if (this.data.isCreating || insert) {
        if (element.parent) {
          element.parent.forEach(item => {
            if (!codevtList.includes(item)) {
              codevtList.push(item);
            }
          });
        }
      } else {
        // if we remove type parent we find children and removing them also
        this.getChildrenTypesSelected(element, codevtList);
      }
    });
    return codevtList;
  }

  /**
   * Build screen data for API
   */
  protected buildScreenData(): any {
    let paramsScreen = {};
    if (this.data.isCreating) {
      paramsScreen = {
        codUt: this.userCode,
        screenHour: this.screenForm.get('screenHour').value,
        screenChannelCode: this.data?.screenChannel?.channelCode ? this.data.screenChannel.channelCode : null,
        screenChannelRegionCode: this.data?.screenChannel?.channelRegionCode ? this.data.screenChannel.channelRegionCode : null,
        screenChannelTemRegion: this.data?.screenChannel?.channelIsRegion ? 'Y' : 'N',
        screenStatus: this.screenForm.get('screenStatus').value,
        screenStartDate: this.data?.screenDate?.startDate
          ? moment(this.data.screenDate?.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        screenEndDate: this.data?.screenDate?.endDate ? moment(this.data.screenDate?.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        screenHourDiff: this.screenForm.get('screenHourDiff').value,
        screenDurMax: this.screenForm.get('screenDurMax').value,
        screenDurOuv: this.screenForm.get('screenDurOuv').value,
        screenForcable: this.screenForm.get('screenForcable').value,
        screenComment: this.screenForm.get('screenComment').value,
        screenDurCalMax: this.screenDurCalMax,
        screenDays: this.data?.screenDays ? this.data.screenDays : null,
        screenPrices: this.screenForm.get('screenPrices').value,
        screenTypes: this.getCodevtList(this.selectedType, true),
      };

      return paramsScreen;
    } else {
      this.screensSelectedCopy.forEach(element => {
        paramsScreen = {
          codUt: this.authService.currentUserData ? this.authService.currentUserData.sub : '',
          screenHour: element.screenHour,
          screenChannelCode: element.screenChannel,
          screenChannelRegionCode: element.screenRegion,
          screenChannelTemRegion: this.data?.screenChannel?.channelIsRegion ? 'Y' : 'N',
          screenStatus: { libtype: element.screenStatus, codtype: element.screenStatusId },
          screenStartDate: moment(element.screenDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          screenHourDiff: element.screenHourDiff.slice(0, 5),
          screenDurMax: element.screenDurMax,
          screenDurOuv: element.screenDurOuv,
          screenForcable: element.screenForcable,
          screenComment:
            this.screenForm.get('screenComment').value === 'Mixte' ? element.screenComment : this.screenForm.get('screenComment').value,
          screenPrices: this.getScreenToUpdatePrice(element, this.data.screenPriceLib),
          screenTypesToDelete: this.getCodevtList(this.screenTypeToDelete, false),
          screenTypesToInsert: this.getCodevtList(this.screenTypeToInsert, true),
        };
        this.screensToUpdate.push(paramsScreen);
      });

      return this.screensToUpdate;
    }
  }

  /**
   * Get original screen price if there is not price update
   * @param screen
   * @param priceLib
   * @returns
   */
  private getScreenToUpdatePrice(screen, priceLib) {
    this.screenToUpdatePrice = {};
    priceLib.forEach(element => {
      for (const [key, value] of Object.entries(screen)) {
        if (element === key) {
          this.screenToUpdatePrice[key] = value;
        }
      }
    });

    return this.screenToUpdatePrice;
  }

  private populateScreenSelectedStatus(): void {
    const screenSelectedStatus = this.screenForm.get('screenStatus').value;
    let identicalScreenStatus = {};

    if (this.screenStatus && this.data) {
      this.screenStatus.forEach(element => {
        if (element['codtype'] === screenSelectedStatus.codtype) {
          identicalScreenStatus = element;
        }
      });

      // if a screen is in status inactif or ID then we disable the input
      if (
        (screenSelectedStatus === 'Mixte' &&
          this.data.screenSelected.find(element => element.screenStatusId === '4' || element.screenStatusId === '5')) ||
        this.data.screenSelected.find(element => element.screenStatusId === '4' || element.screenStatusId === '5')
      ) {
        this.screenForm.get('screenStatus').disable();
      } else {
        this.screenStatus = [];
        this.screenStatus = this.screenStatusToUse;
      }

      this.screenForm.get('screenStatus').setValue(identicalScreenStatus, { emitEvent: false });
    }
  }

  private populateScreenSelectedType(): void {
    this.screenSelectedType = '';

    this.screensSelectedCopy.forEach(element => {
      if (element.screenTypeLib) {
        if (this.screenSelectedType !== '') {
          this.screenSelectedType = this.screenSelectedType + ', ' + element.screenTypeLib;
        } else {
          this.screenSelectedType = element.screenTypeLib;
        }
      }
    });

    const re = /\s*(?:,|$)\s*/;
    const typeList = this.screenSelectedType.split(re);

    if (this.data.isCreating || (!this.data.isCreating && this.screensSelectedCopy.length === 1)) {
      typeList.forEach(element => {
        this.deepSearch(this.screenTypes, element, this.selectedType);
      });
    } else {
      return this.separateCommonAndIndividualType(typeList);
    }
  }

  private separateCommonAndIndividualType(typeList) {
    const counts = {};

    typeList.forEach(item => {
      if (!counts[item]) {
        counts[item] = 1;
      } else {
        counts[item]++;
      }
    });

    for (const key in counts) {
      if (counts[key] === this.screensSelectedCopy.length) {
        this.deepSearch(this.screenTypes, key, this.selectedType);
      } else {
        this.deepSearch(this.screenTypes, key, this.individualTypes);
      }
    }
  }

  /**
   * get children type selected
   * @param element
   * @param codevtList
   */
  private getChildrenTypesSelected(element, codevtList) {
    if (element.children) {
      element.children.forEach(item => {
        if (item.isSelected) {
          codevtList.push(item.codevt);
        }
        this.getChildrenTypesSelected(item, codevtList);
      });
    }
  }

  private deepSearch(arr, target, arrayToDisplay): any {
    for (let obj of arr) {
      for (let key in obj) {
        if (key === 'libevt' && obj[key] === target) {
          if (!arrayToDisplay.includes(obj)) {
            obj.isSelected = true;
            arrayToDisplay.push(obj);
          }
        } else {
          if (typeof obj[key] === 'object') {
            this.deepSearch(obj[key], target, arrayToDisplay);
          }
        }
      }
    }
    return null;
  }
}
