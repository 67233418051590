// Menus
import { MenuItem } from '@shared/components/headers/main-header/main-header.component';

export const ORMenu: MenuItem[] = [
  {
    label: 'Gestion des écrans',
    link: '/or/screens',
    icon: 'assets/images/icons/tv-icons/grid-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Gestion des ID',
    link: '/or/broadcast-incidents',
    icon: 'assets/images/icons/tv-icons/id-icon.svg',
    isLastInSection: false,
  },
  // TODO: Uncomment when working on this part
  // {
  //   label: 'Creation en masse',
  //   link: '/or/batch-create',
  //   icon: 'assets/images/icons/tv-icons/stack-icon.svg',
  //   isLastInSection: false,
  // },
  {
    label: 'Mise à jour en masse',
    link: '/or/batch-update',
    icon: 'assets/images/icons/tv-icons/batch-update-icon.svg',
    isLastInSection: true,
  },
  // TODO: Uncomment when working on this part
  // {
  //   label: 'Tarifs',
  //   link: '/or/rates',
  //   icon: 'assets/images/icons/tv-icons/rate-icon.svg',
  //   isLastInSection: false,
  // },
  // TODO: Uncomment when working on this part
  // {
  //   label: 'Alerting',
  //   link: '/or/notifications',
  //   icon: 'assets/images/icons/tv-icons/notification-icon.svg',
  //   isLastInSection: false,
  // },
  {
    label: 'Paramétrage',
    link: '/or/channel-settings',
    icon: 'assets/images/icons/tv-icons/settings-icon.svg',
    isLastInSection: true,
  },
];

export const OldMenu: MenuItem[] = [
  {
    label: 'TV segmentée',
    link: '/tv-adressee',
    icon: 'assets/images/icons/tv-icons/segmented-tv-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'TV linéaire',
    link: '/tv-lineaire',
    icon: 'assets/images/icons/tv-icons/linear-tv-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Mes ressources',
    link: '/resources',
    icon: 'assets/images/icons/tv-icons/resources-icon.svg',
    isLastInSection: false,
  },
  /*  {
    label: 'Pop dom',
    link: '/popdom',
    icon: 'assets/images/icons/tv-icons/pop-dom.svg',
    isLastInSection: false,
  },*/
  {
    label: 'Affichage Campagne',
    link: '/planning/campaign-display',
    icon: 'assets/images/icons/tv-icons/segmented-tv-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Paramétrage',
    link: '/general-settings',
    icon: 'assets/images/icons/tv-icons/settings-icon.svg',
    isLastInSection: false,
  },
];

export const AdvMenu: MenuItem[] = [
  {
    label: 'Encours Client',
    link: '/adv/oustanding-balance',
    icon: 'assets/images/icons/tv-icons/rate-icon.svg',
    isLastInSection: false,
  },
];

export const CommercialMenu: MenuItem[] = [
  {
    label: 'Liste des contrats',
    link: '/commercial/contracts',
    icon: 'assets/images/icons/tv-icons/grid-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Transfert de contrats',
    link: '/commercial/contract-transfer',
    icon: 'assets/images/icons/tv-icons/batch-update-icon.svg',
    isLastInSection: false,
  },
];

export const FtpioBoulogneMenu: MenuItem[] = [
  {
    label: 'Pop dom',
    link: '/popdom',
    icon: 'assets/images/icons/tv-icons/pop-dom.svg',
    isLastInSection: false,
  },
  {
    label: 'Gestion des écrans',
    link: '/or/screens',
    icon: 'assets/images/icons/tv-icons/grid-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Gestion des ID',
    link: '/or/broadcast-incidents',
    icon: 'assets/images/icons/tv-icons/id-icon.svg',
    isLastInSection: false,
  },
];

export const FtpioMenu: MenuItem[] = [
  {
    label: 'Pop dom',
    link: '/popdom',
    icon: 'assets/images/icons/tv-icons/pop-dom.svg',
    isLastInSection: false,
  },
];

export const MenuList: Record<string, any> = {
  devMenu: ORMenu,
  commercialMenu: CommercialMenu,
  orMenu: ORMenu,
  planningMenu: OldMenu,
  advMenu: AdvMenu,
  ftpioBoulogneMenu: FtpioBoulogneMenu,
};

export const DevMenuOptions = [
  {
    label: 'Menu Or',
    value: ORMenu,
  },
  {
    label: 'Menu Planning',
    value: OldMenu,
  },
  {
    label: 'Menu Adv',
    value: AdvMenu,
  },
  {
    label: 'Menu Commercial',
    value: CommercialMenu,
  },
  {
    label: 'Menu FTPIO Boulogne',
    value: FtpioBoulogneMenu,
  },
  {
    label: 'Menu FTPIO',
    value: FtpioMenu,
  },
];
