import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { ContractService } from '@core/services/contract/contract.service';
import { ContractSeller, ContractSellerItem } from '@core/models/contract-seller.model';
import { ChangeDetectorRef } from '@angular/core';
import { TvConfirmationData } from '@core/models/tv-confirmation-data.model';
import { TvConfirmPopupComponent } from '@shared/dialog/tv-confirm-popup/tv-confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contract-seller-list',
  templateUrl: './contract-seller-list.component.html',
  styleUrls: ['./contract-seller-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'YYYY' },
        display: { dateInput: 'YYYY' }
      }
    },
  ],
})

export class ContractSellerListComponent implements OnInit, AfterViewInit {
  isLoadingItems = true;
  sellerList: ContractSeller[];
  contractSellerItems: ContractSellerItem[];
  contractSellerItemsList = new MatTableDataSource<ContractSellerItem>([]);
  transferContracts: ContractSellerItem[] = [];
  filter: { year: string } = { year: this.getCurrentYear() };
  selectedSeller: ContractSeller | null = null;
  sellerToTransferList: ContractSeller[];
  contractSellerToTransferItemsList = new MatTableDataSource<ContractSeller>([]);

  constructor(
    private contractService: ContractService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getSellerByYear();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  getSellerByYear(): void {
    this.isLoadingItems = true;
    this.contractService.getSellerByYear(this.filter.year).subscribe({
      next: (sellers) => {
        this.sellerList = sellers;
        this.isLoadingItems = false;
      },
      error: (error) => {
        console.error('Error while getting seller list: ', error);
        this.sellerList = null;
        this.isLoadingItems = false;
      }
    });
  }

  handleSellerChange(event: any): void {
    this.isLoadingItems = true;
    this.filter.year = event?.year;
    this.selectedSeller = event?.seller;

    this.contractService.getContractSellerListByYear(this.getCurrentYear(), this.selectedSeller?.codUt)
      .subscribe({
        next: (contractSellerItems) => {
          this.contractSellerItems = contractSellerItems;
          this.contractSellerItemsList = new MatTableDataSource<ContractSellerItem>(contractSellerItems);

          if (this.selectedSeller) {
            this.getContractSellerToTransfer();
          } else {
            this.isLoadingItems = false;
          }
        },
        error: (error) => {
          console.error('Error while getting contract seller list: ', error);
          this.contractSellerItems = null;
          this.contractSellerItemsList = new MatTableDataSource<ContractSellerItem>([]);
          this.isLoadingItems = false;
        }
      });
  }

  handleContractToTransferListEvent(event: { ContractSellerToTransfer: ContractSellerItem[] }): void {
    this.transferContracts = event.ContractSellerToTransfer;
  }

  handleTransferContractsEmitter(event: { sellerToTransfer: ContractSeller }): void {
    const confirmationData: TvConfirmationData = {
      title: `Confirmation de transfert (${this.filter?.year})`,
      content: `Êtes-vous sûr de vouloir transférer :
        * ${this.transferContracts.length} contrat(s)
        de ${this.selectedSeller?.nomUt} (${this.selectedSeller?.codUt})
        à ${event.sellerToTransfer?.nomUt} (${event.sellerToTransfer?.codUt}) ?`,
      okButtonTitle: 'Oui',
      cancelButtonTitle: 'Non',
    };

    const dialogRef =
      this.dialog.open(TvConfirmPopupComponent, {
        data: confirmationData,
        panelClass: 'tv-ns-dialog-container',
      });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.isLoadingItems = true;
        this.transferContractsToSeller(event.sellerToTransfer);
      }
    });
  }

  getContractSellerToTransfer(): void {
    this.contractService.getContractSellerToTransfer(this.getCurrentYear(), this.selectedSeller?.codUt ?? '')
      .subscribe({
        next: (sellers) => {
          this.sellerToTransferList = sellers;
          this.contractSellerToTransferItemsList = new MatTableDataSource<ContractSeller>(sellers);
          this.isLoadingItems = false;
        },
        error: (error) => {
          console.error('Error while getting contract seller to transfer list: ', error);
          this.isLoadingItems = false;
        }
      });
  }

  transferContractsToSeller(sellerToTransfer: ContractSeller): void {
    this.contractService.transferContractsToSeller(
      this.filter?.year,
      this.selectedSeller?.codUt,
      sellerToTransfer?.codUt,
      this.transferContracts
    ).subscribe({
      next: () => {
        this.isLoadingItems = false;
        this.transferContracts = [];
        this.refreshContractList();

        this.snackBar.open('Le transfert des contrats a été effectué', null, {
          duration: 2000,
          verticalPosition: 'top',
          panelClass:  ['tv-chip-success'],
        });
      },
      error: (error) => {
        console.error('Error while transferring contracts: ', error);
        this.isLoadingItems = false;
      }
    });
  }

  refreshContractList(): void {
    this.contractService.getContractSellerListByYear(this.filter?.year, this.selectedSeller?.codUt)
      .subscribe({
        next: (contractSellerItems) => {
          this.contractSellerItems = contractSellerItems;
          this.contractSellerItemsList = new MatTableDataSource<ContractSellerItem>(contractSellerItems);
        },
        error: (error) => {
          console.error('Error while refreshing contract list: ', error);
          this.contractSellerItems = null;
          this.contractSellerItemsList = new MatTableDataSource<ContractSellerItem>([]);
        }
      });
  }

  getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }
}
