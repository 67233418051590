export interface JsonContractSeller {
  CODUT: string;
  NOMUT: string;
}

export class ContractSeller {
  codUt: string;
  nomUt: string;

  constructor(JsonContractSeller: JsonContractSeller = null) {
    this.codUt = JsonContractSeller.CODUT;
    this.nomUt = JsonContractSeller.NOMUT;
  }
}

export class JsonContractSellerItem {
  NUMCLIENT_A: string;
  ANNEE: string;
  CODUT_GES: string;
  CODUT_COM: string;
  STATUT: string;
  NOMANN: string;
  NOMAGE: string;
}

export interface JsonContractSellerTransferItem {
  NUMCLIENT_A: string;
  ANNEE: string;
  CODUT_GES: string;
  CODUT_COM: string;
  STATUT: string;
  NOMANN: string;
  NOMAGE: string;
}

export class ContractSellerItem {
  numClientA: string;
  year: string;
  codUtGes: string;
  codUtCom: string;
  status: string;
  comAnn: string;
  nomAge: string;
  transfer?: boolean = false;

  constructor(jsonContractSellerItem: JsonContractSellerItem = null) {
    this.numClientA = jsonContractSellerItem.NUMCLIENT_A;
    this.year = jsonContractSellerItem.ANNEE;
    this.codUtGes = jsonContractSellerItem.CODUT_GES;
    this.codUtCom = jsonContractSellerItem.CODUT_COM;
    this.status = jsonContractSellerItem.STATUT;
    this.comAnn = jsonContractSellerItem.NOMANN;
    this.nomAge = jsonContractSellerItem.NOMAGE;
  }

  toTransferJson(): JsonContractSellerTransferItem {
    return {
      NUMCLIENT_A: this.numClientA,
      ANNEE: this.year,
      CODUT_GES: this.codUtGes,
      CODUT_COM: this.codUtCom,
      STATUT: this.status,
      NOMANN: this.comAnn,
      NOMAGE: this.nomAge
    };
  }
}

export interface AllDisplayedColumns {
  [key: string]: {
    isActive: boolean;
    canBeHidden: boolean;
    label: string | null;
    type?: string;
    toolType?: string;
  };
}
