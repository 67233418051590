export interface JsonBroadcastIncidentScreen {
  BREAK_CODE: string;
  BROADCAST_DATE: string;
  BROADCAST_TIME: string;
  BROADCAST_TIME_THEO: string;
  DURATION_SOLD: string;
  DURATION_MAX: string;
  TYPE_LABELS: string;
  CA_NET: string;
  CA_LOST: string;
  CHANNEL_CODE: string;
  REGION_CODE: string;
  IS_REGION: string;
  TOTAL_NB_SPOTS: string;
  NB_ID_SPOTS: string;
}

export class BroadcastIncidentScreen {
  breakCode: string;
  broadcastDate: string;
  broadcastTime: string;
  broadcastTimeTheo: string;
  durationSold: number;
  durationMax: number;
  isBillboard: boolean;
  caNet: number;
  caLost: number;
  channelCode: string;
  regionCode: string;
  displayRegionCode: string;
  isRegion: boolean;
  totalNbSpots: number;
  nbIdSpots: number;

  constructor(jsonBroadcastIncidentScreen: JsonBroadcastIncidentScreen) {
    this.breakCode = jsonBroadcastIncidentScreen.BREAK_CODE;
    this.broadcastDate = jsonBroadcastIncidentScreen.BROADCAST_DATE;
    this.broadcastTime = jsonBroadcastIncidentScreen.BROADCAST_TIME;
    this.broadcastTimeTheo = jsonBroadcastIncidentScreen.BROADCAST_TIME_THEO;
    this.caNet = Number(jsonBroadcastIncidentScreen.CA_NET);
    this.caLost = Number(jsonBroadcastIncidentScreen.CA_LOST);
    this.durationSold = Number(jsonBroadcastIncidentScreen.DURATION_SOLD);
    this.durationMax = Number(jsonBroadcastIncidentScreen.DURATION_MAX);
    if (jsonBroadcastIncidentScreen.TYPE_LABELS && jsonBroadcastIncidentScreen.TYPE_LABELS.trim().length > 0) {
      const types = jsonBroadcastIncidentScreen.TYPE_LABELS.trim().split(/,\s*/);
      this.isBillboard = types.includes('BILLBOARD');
    } else {
      this.isBillboard = false;
    }
    this.channelCode = jsonBroadcastIncidentScreen.CHANNEL_CODE;
    this.regionCode = jsonBroadcastIncidentScreen.REGION_CODE;
    this.isRegion = jsonBroadcastIncidentScreen.IS_REGION === 'Y';
    if (this.isRegion) {
      if (this.regionCode === 'NAT') {
        this.displayRegionCode = 'REG NAT';
      } else {
        this.displayRegionCode = this.regionCode;
      }
    } else {
      this.displayRegionCode = '-';
    }
    this.totalNbSpots = Number(jsonBroadcastIncidentScreen.TOTAL_NB_SPOTS);
    this.nbIdSpots = Number(jsonBroadcastIncidentScreen.NB_ID_SPOTS);
  }
}
