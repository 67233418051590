import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuList } from '@app/app.menus';
import { AuthService } from '@core/services/auth.service';
import { FacadeService } from '@core/services/facade.service';
import { MenuItem } from '@shared/components/headers/main-header/main-header.component';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  identity: any;
  menuItems: MenuItem[];
  isDev = false;

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private facadeService: FacadeService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    moment.locale(this.localeId);
    this.addIcons();
    this.onGetUserInfo();
    this.onUserInfoChangedSubscribe();
    this.facadeService.auth.init();
  }

  private onGetUserInfo() {
    this.facadeService.auth.onInit().subscribe(() => {
      this.facadeService.auth.getUserInfo().subscribe(info => {
        if (info) {
          this.checkAccess(info);
        }
      });
    });
  }

  private onUserInfoChangedSubscribe() {
    this.facadeService.auth.onUserInfoChanged().subscribe(info => {
      this.identity = info;
      if (!info) {
        this.facadeService.auth.logout();
      } else if (this.facadeService.auth.hasRefresHToken()) {
        this.facadeService.auth.startTokenRefreshTimeout();
      } else {
        this.checkAccess(info);
      }
    });
  }

  handleLogout() {
    this.facadeService.auth.logout();
  }

  private addIcons() {
    this.matIconRegistry.addSvgIcon(
      `parrainage`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/icons/parrainage.svg`)
    );
    this.matIconRegistry.addSvgIcon(`digital`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/icons/digital.svg`));
    this.matIconRegistry.addSvgIcon(`booking`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/icons/booking.svg`));
    this.matIconRegistry.addSvgIcon(
      `parametrage`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/icons/parametrage.svg`)
    );
    this.matIconRegistry.addSvgIcon(`tv`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/icons/tv.svg`));
  }

  private checkAccess(info): any | boolean {
    if (!this.authService.formatOu(info.DistinguishedName)) {
      return;
    }

    const OUCode = this.authService.formatOu(info.DistinguishedName).toUpperCase();
    this.authService.checkAccess(OUCode).subscribe(
      response => {
        if (response) {
          this.chooseMenu(response);
        }
      },
      () => {}
    );
  }

  private chooseMenu(response) {
    // Find the first menu key that exists in the response and is true
    const menuKey = Object.keys(MenuList).find(key => response[key]);

    if (menuKey) {
      this.menuItems = MenuList[menuKey];

      // Special handling for "dev"
      if (menuKey === 'devMenu') {
        this.isDev = true;
      }
    } else {
      this.router.navigate(['/']);
      this.snackBar.open("Vous n'êtes pas autorisé à consulter cette page.", null, {
        duration: 4000,
        verticalPosition: 'top',
      });
    }
  }
}
