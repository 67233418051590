import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacadeService } from '@core/services/facade.service';

export interface ResourceFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private route = '/rbac-resource';
  public rights: any;
  public resources: any;

  constructor(private http: HttpClient, private facadeService: FacadeService) {}

  /**
   * Check if user has access by url
   * @param route
   */
  public isUrlValid(route, rights): boolean {
    if (!rights) {
      return false;
    }

    if (rights.devMenu || rights.planningMenu) {
      return true;
    }

    //TODO: check if we can change to 2 elements
    const numElementsToCheck = 1;
    const elements = route.slice(1).split('/').slice(0, numElementsToCheck);

    return elements.every(element => rights[element] === true);
  }
}
