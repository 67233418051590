import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Contract } from '@core/models/contract.model';
import { ContractSeller } from '@core/models/contract-seller.model';

@Component({
  selector: 'app-contract-seller-selector',
  templateUrl: './contract-seller-selector.component.html',
  styleUrls: ['./contract-seller-selector.component.scss']
})
export class ContractSellerSelectorComponent {

  @Input() public sellerList: ContractSeller[];
  @Input() public selectedSeller: ContractSeller = null ;
  @Input() public selectedSellerPlaceholder = "Commercial(e)";

  @Output() public sellerChanged = new EventEmitter<ContractSeller>();

  public showClearButton = false;
  public searchFormControl = new FormControl();
  public items = new MatTableDataSource<Contract>([]);

  public sellerFiltered: ContractSeller[];

  searchCommercial($event) {
    const search = $event.target.value;
    if ( search.length > 0 ) {
      this.sellerFiltered = this.sellerList?.filter(
        (item) => item.nomUt.toLowerCase().includes(search.toLowerCase())
      ) ?? [];
    } else {
      this.sellerFiltered = this.sellerList;
    }
  }

  init() {
    this.sellerList = [];
    this.selectSeller();
  }

  selectSeller(seller = null) {
    this.searchFormControl.setValue('');
    this.selectedSeller = null;
    this.sellerFiltered = [];
    this.selectedSeller = seller;
    this.sellerChanged.emit(this.selectedSeller);
  }
}
