import { Component, EventEmitter, Output } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-year-filter',
  templateUrl: './year-filter.component.html',
  styleUrls: ['./../date-range-picker/date-range-picker.component.scss']
})
export class YearFilterComponent {
  @Output() yearSelected = new EventEmitter<number>();

  yearFormControl = new FormControl();
  currentYear = null;

  ngOnInit() {
   this.initCurrentYear();
  }

  handleYearDatepickerValueChange(event = null, datepicker: any = null): void {
    this.currentYear = event?._i?.year.toString() ?? null;
    this.yearFormControl.setValue(this.currentYear);
    datepicker?.close() ;

    this.yearSelected.emit(this.currentYear);
  }

  initCurrentYear() {
    this.currentYear = new Date().getFullYear().toString();
    this.yearFormControl.setValue(this.currentYear);

    this.yearSelected.emit(new Date().getFullYear());
  }
}
