import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ContractSellerItem, AllDisplayedColumns, ContractSeller } from '@core/models/contract-seller.model';

@Component({
  selector: 'app-contract-seller-grid',
  templateUrl: './contract-seller-grid.component.html',
  styleUrls: ['./contract-seller-grid.component.scss']
})
export class ContractSellerGridComponent implements OnInit {
  @Input() contractSellerItemsList!: MatTableDataSource<any>;
  @Input() contractSellerItems!: ContractSellerItem[];
  @Input() isLoadingItems!: boolean;
  @Input() sellerToTransfer!: ContractSeller | null;

  @Output() public contractToTransferListEvent=
    new EventEmitter<{ ContractSellerToTransfer: ContractSellerItem[]; }>();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('transferAllContracts') transferAllContracts;

  public searchControl = new FormControl('');
  public allDisplayedColumns: AllDisplayedColumns = {
    transfer: {
      isActive: true,
      canBeHidden: true,
      label: null,
      type: "checkbox",
      toolType: 'Transférer le contrat'
    },
    numClientA: { isActive: true, canBeHidden: true, label: 'Numéro client' },
    year: { isActive: true, canBeHidden: true, label: 'Année' },
    status: { isActive: true, canBeHidden: true, label: 'Statut' },
    comAnn: { isActive: true, canBeHidden: true, label: 'Annonceur' },
    nomAge: { isActive: true, canBeHidden: true, label: "Nom de l'Agence" }
  };  public displayedColumns: string[] = [];
  public transferAllChecked: boolean = false;

  ngOnInit(): void {
    this.applyFilterToTable();
  }

  clearSearchInput(): void {
    this.searchControl.setValue('');
    this.contractSellerItemsList.filter = '';
  }

  applyFilterToTable = () => {
    this.isLoadingItems = true;
    this.displayedColumns = Object.entries(this.allDisplayedColumns)
      .filter(([key, column]) =>
        column.isActive && (key !== 'transfer' || !this.sellerToTransfer))
      .map(([key, _]) => key as string);
    this.isLoadingItems = false;
  };

  onSortChange(): void {
    this.contractSellerItemsList.sort = this.sort;
  }

  onSearchInput(event: Event): void {
    const searchValue = (event.target as HTMLInputElement).value;
    this.contractSellerItemsList.filter = searchValue.length >= 1 ? searchValue.toLowerCase() : '';
  }

  onTransferChange(item: ContractSellerItem, column: string): void {
    item[column] = !item[column];
    this.contractSellerItemsList.data = [...this.contractSellerItemsList.data];
    this.transferAllChecked = !this.contractSellerItemsList.data.find(
      (contractSellerItem) => contractSellerItem.transfer === false
    );

    this.transferAllContracts.checked = this.transferAllChecked;
    this.notifyContractSellerListTransferChange();
  }

  onTransferAllChange(event): void {
    this.contractSellerItemsList.data.forEach(contractSellerItem => {
      contractSellerItem.transfer = event.checked;
    });
    this.notifyContractSellerListTransferChange();
  }

  notifyContractSellerListTransferChange() {
    this.contractToTransferListEvent.emit({
        ContractSellerToTransfer: this.contractSellerItemsList.data.filter(
          contractSellerItem => contractSellerItem?.transfer === true
        )});
  }
}
