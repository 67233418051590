/* Contains all application constants */

export const DEFAULT_LOCALE = 'fr-FR';

export const ThemaDiffusionTimeRange = {
  minHours: 3,
  maxHours: 29,
  minMinutes: 0,
  maxMinutes: 59,
};

export const ThemaBreakCodeRange = {
  minHours: 3,
  maxHours: 27,
  minMinutes: 0,
  maxMinutes: 99,
};

export const NbcId = '2';

export const FileType = {
  grid: 1,
  gridContext: 2,
};

export const TvFileStatus = {
  abandoned: 4,
  done: 2,
};

export const RegGroupId = 'F';

export const ScreenStatus = {
  close: 2,
  open: 3,
  inactive: 4,
  broadcastIncident: 5,
};
