/* La liste des ressources de l'application gérée par AdspaceManager pour attribuer les droits */
export const TvResources = [
  { resource_module: 'devMenu', permission_code: 'R' },
  { resource_module: 'planningMenu', permission_code: 'R' },
  { resource_module: 'orMenu', permission_code: 'R' },
  { resource_module: 'commercialMenu', permission_code: 'R' },
  { resource_module: 'advMenu', permission_code: 'R' },
  { resource_module: 'ftpioBoulogneMenu', permission_code: 'R' },
  { resource_module: 'ftpioMenu', permission_code: 'R' },
  { resource_module: 'adv', permission_code: 'R' },
  { resource_module: 'oustanding-balance', permission_code: 'R' }, // adv/oustanding-balance
  { resource_module: 'or', permission_code: 'R' },
  { resource_module: 'screens', permission_code: 'R' }, // or/screens --> Gestion des écrans
  { resource_module: 'broadcast-incidents', permission_code: 'R' }, // or/broadcast-incidents
  { resource_module: 'batch-update', permission_code: 'R' }, // or/batch-update
  { resource_module: 'channel-settings', permission_code: 'R' }, // or/settings
  { resource_module: 'popdom', permission_code: 'R' },
  { resource_module: 'tv-adressee', permission_code: 'R' },
  { resource_module: 'tv-lineaire', permission_code: 'R' },
  { resource_module: 'list', permission_code: 'R' }, //tv-lineaire/list
  { resource_module: 'resources', permission_code: 'R' },
  { resource_module: 'campaign-display', permission_code: 'R' },
  { resource_module: 'general-settings', permission_code: 'R' },
  { resource_module: 'commercial', permission_code: 'R' },
  { resource_module: 'contracts', permission_code: 'R' }, // commercial/contracts
  { resource_module: 'contract-transfer', permission_code: 'R' }, // commercial/contract-transfer
];
