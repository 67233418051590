import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { FacadeService } from '@core/services/facade.service';
import { ResourceService } from '@core/services/resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private facadeService: FacadeService,
    private router: Router,
    private snackBar: MatSnackBar,
    private resourceService: ResourceService,
    private authService: AuthService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.facadeService.auth.isAuthorized().pipe(
      map(isAuthorized => {
        if (!isAuthorized) {
          this.facadeService.auth.redirectUrl = state.url || '';
          this.router.navigate(['/login']);
          return false;
        }
        if (window.location.pathname !== '/login/callback') {
          if (window.location.pathname !== '/') {
            this.authService.getUserInfo().subscribe(info => {
              const OUCode = this.authService.formatOu(info.DistinguishedName).toUpperCase();
              this.authService.checkAccess(OUCode).subscribe(
                response => {
                  const isAuthorisedToAccesUrl = this.resourceService.isUrlValid(window.location.pathname, response);
                  if (!isAuthorisedToAccesUrl) {
                    this.snackBar.open("Vous n'êtes pas autorisé à consulter cette page !", null, {
                      duration: 4000,
                      verticalPosition: 'top',
                      panelClass: ['chip-error'],
                    });
                    this.router.navigate(['/']);

                    return false;
                  }
                },
                () => {
                  return false;
                }
              );
            });
          }
        }
        this.facadeService.auth.handleTokenChange(this.facadeService.auth.getToken());
        return isAuthorized;
      })
    );
  }
}
